<template>
  <div id="section_carrito">
    <HeaderHome :name="user.fullname" />
    <AnuncioBar />
    <h1 id="title_sec_car">MI CARRITO</h1>

    <div v-if="cart.detalle.length > 0" id="tab_car">
      <div id="items_car_list">
        <div>
          <div class="div_item">
            <!-- 
            <div id="title_car_list">
            <h4>PRODUCTO</h4> 
           
            <h4>CANTIDAD</h4>
            <h4 class="Bar_Precio">PRECIO</h4>
                    
        </div>-->
       
            <div
              class="item_car_list"
              v-for="(item, key) in cart.detalle"
              :key="key"
            >
              <p class="Deleted_Icon">
                <span @click="deleteItem(key, item.codigo)" class="ie_item_car"
                  >&#10006;</span
                >
              </p>
              <div class="img_car_item">
                <img
                  :src="urlpath.url() + '/get-product-image/' + item.image"
                  :alt="'imagen ' + item.nombre"
                />
              </div>
              <div class="DivDet">
                <div>
                  <p class="title_product">PRODUCTO</p>
                  <p class="item_car_name" >
                    {{ item.nombre }}
                  </p>
                  <p class="TallaColor">{{ item.talla }} | {{ item.color }}</p>
                  <p class="Text_Producto">{{ item.descripcion }}</p>
                  <p class="TallaColor2">{{ item.talla }} | {{ item.color }}</p>
                </div>

                <div>
                  <p class="title_product">CANTIDAD</p>
                  <div class="cantCar">
                    <button @click="res(key)">-</button>
                    <p class="Text_Cantidad">
                      <input
                        v-model="item.cantidad"
                        type="number"
                        min="1"
                        :max="item.disponibles"
                      />
                    </p>
                    <button @click="sum(key)">+</button>
                    <img
                      @click="deleteItem(key, item.codigo)"
                      src="../../assets/delete2.png"
                    />
                  </div>
                </div>

                <div>
                  <p class="title_product">PRECIO</p>
                  <p class="Text_Precio">${{ (item.precio * item.cantidad).toFixed(2) }}MXN</p>
                </div>
              </div>
            </div>
          </div>

          <div class="cupon-cont" v-if="cart.cupon.length == 0">
            <p>Sin Cupones</p>
          </div>

          <div v-else>
            <div class="DivCupones">
              <p class="TC_Title">CUPONES</p>
              <table class="TableCupones">
                <tr>
                  <th class="TC_Delet"></th>
                  <th class="TC_Code">CÓDIGO</th>
                  <th class="TC_Desc">DESCUENTO</th>
                </tr>
                <tr class="TC_F2" v-for="(item, key) in cart.cupon" :key="key">
                  <td>
                    <p class="TC_DDelet">
                      <span @click="deleteCupon(item.code)" class="ie_item_car"
                        >&#10006;</span
                      >
                    </p>
                  </td>
                  <td class="TC_DCode">{{ item.code }}</td>
                  <td class="TC_DDesc">-${{ item.descuento }}</td>
                </tr>
              </table>
            </div>
          </div>

          <div class="Div_Cupon">
            <button class="Actualizar_Carrito" :class="{'active-btn' : upd}" @click="actualizar(cart)">
              Actualizar carrito
            </button>
            <div class="">
              <input
                class="Input_Cupon"
                v-model="cupon"
                required
                id="inputc"
                type="text"
                placeholder="CÓDIGO CUPÓN"
              />
            </div>

            <button class="Aplicar_Cupon" @click="aplicarCupon(cupon)">
              APLICAR CUPÓN<img src="../../assets/arrow2.png" />
            </button>
          </div>
        </div>
        <!--<div class="WHITELINE"></div>-->

        <div class="Pedido_Total">
          <p class="Tupedido_Text">TU PEDIDO</p>

          <div id="car_list_total">
            <div class="car_subtotal">
              <div class="PedidoF2">
                <h4 class="PedidoF2_Producto">PRODUCTO</h4>
                <h4 class="PedidoF2_Precio">PRECIO</h4>
              </div>

              <div class="PedidoF3">
                <div
                  class="PedidoF4"
                  v-for="(item, key) in cart2.detalle"
                  :key="key"
                >
                  <div class="Div_TuProducto">
                    <p class="Text_Name" >
                      {{ item.nombre }}
                    </p>
                    <p class="Text_Pedido">{{ item.descripcion }}</p>
                    <p class="Pedido_Cantidad">
                      x<input
                        v-model="item.cantidad"
                        type="number"
                        min="1"
                        :max="item.disponibles"
                      />
                    </p>
                  </div>
                  <p class="Precop_Pedido">${{ (item.precio * item.cantidad).toFixed(2) }}</p>
                </div>
              </div>
            </div>

            <div class="GoldLine"></div>

            <div class="Div_Subtotal">
              <h4>SUBTOTAL</h4>
              <p>${{ cart2.subtotal }}</p>
            </div>

            <div class="GoldLine"></div>

            <div class="Div_Envio">
              <h4>ENVIO</h4>
              <p> ${{ cart2.envio }}</p>
            </div>

            <div class="GoldLine"></div>

            <div class="Div_Desc">
              <h4>CUPÓN DE DESCUENTO</h4>
              <p>-${{ cart2.descupon }}</p>
            </div>

            <div class="GoldLine"></div>

            <div class="Div_Total">
              <h4>TOTAL</h4>
              <p>${{ cart2.total }}</p>
            </div>

            <div>
              <button v-if="cart2.total < 10000" class="Fin_Compra" @click="finalizarCompra(cart)">
                FINALIZAR COMPRA<img src="../../assets/arrow2.png" />
              </button>
              <p v-else class="cart_msg cart_error">LIMITE DE COMPRA SUPERADO, EL TOTAL DEBE SER MENOR A $10,000 MXN.</p>
            </div>
          </div>

          <div v-if="this.status != ''">
              <div v-if="this.status == 'success'">
                <p class="cart_msg cart_success">{{ this.msg }}</p>
              </div>
              <div v-if="this.status == 'error'">
               
                <p v-if="this.msg =='Debes iniciar sesión para finalizar tu compra.'" ><a href="/login">Inicia sesión </a> para finalizar tu compra.</p>
                 <p v-else class="cart_msg cart_error">{{ this.msg }}</p>
              </div>
            </div>
        </div>
      </div>
    </div>

    <div v-else class="Cont_Empty">
      <div id="items_car_list1">
        <p class="msg_carrito_vacio">Tu carrito aún está vacío.</p>
      </div>
      <div @click="goTienda()" class="div_BackTienda">
        <img src="../../assets/Arrow_4.png" />
        <p>VOLVER A LA TIENDA</p>
      </div>
    </div>

    <img class="imglogoc" src="../../assets/Cimarrones-mx.png" alt="" />

    
    <Footer />
  </div>
</template>
<script>
import Footer from "../../components/footer";
import HeaderHome from "../../components/HeaderHome";
import IconSuccess from "../../assets/Vector.png";
import AnuncioBar from "../../components/AnuncioBar";
let urlpath = require("../../global/url");
import { mapActions } from "vuex";
export default {
  name: "Carrito",
  components: {
    HeaderHome,
    Footer,
    AnuncioBar,
  },
  data() {
    return {
      cantidad: "",
      status: "",
      msg: "",
      urlpath,
      subtotal: 0,
      envio: 50,
      cupon: "",
      upd:false,
    };
  },
  created() { 
    this.getCar();
    this.getCar2();
  },
  async beforeMount() {
    let token = await this.$store.dispatch("admin/getToken");
    this.$store.dispatch("admin/decode", token);
  },
  computed: {
    url() {
      return this.$store.getters["main/baseURL"];
    },
    cart() {
      return this.$store.getters["cart/data"];
    },
    cart2() {
      let data = this.$store.getters["cart2/data"];
      if(data.detalle && Array.isArray(data.detalle)){
        for(var i =0; i<data.detalle.length; i++){
            if(data.detalle[i].cantidad != this.cart.detalle[i].cantidad){
              this.upd = true
              break
            }else{
              this.upd = false
            }
        }
      }
      
      return data
    },
    user() {
      return this.$store.getters["admin/getIdentity"];
    },
  },
  methods: {
    ...mapActions("cart", ["getCar"]),
    ...mapActions("cart2", ["getCar2"]),
    wait: function () {
      setTimeout(() => this.$router.go(), 200);
    },

    refresh: function () {
      setTimeout(() => this.$router.go());
    },
    delStatus: function () {
      setTimeout(() => this.delMsgs(), 1000);
      setTimeout(() => this.refresh(), 500);
    },
    delStatus2: function () {
      setTimeout(() => this.delMsgs(), 2000);
      setTimeout(() => this.refresh(), 500);
    },
     delStatus3: function () {
      setTimeout(() => this.delMsgs(), 5000);
      
    },
    delMsgs: function () {
      this.status = "";
      this.msg = "";
    },
    finalizarCompra: async function () {
    window.fbq('track', 'InitiateCheckout');
      //TODO: Validar el carrito...
      let usertoken = localStorage.getItem('token')
      if(!this.user.sub || !usertoken){
        this.status = 'error';
        this.msg = 'Debes iniciar sesión para finalizar tu compra.';
        this.delStatus3();
        return null
      }

      let result = await this.$store.dispatch("cart/validateCart", this.cart);

      if (result.status == "success") {
        this.$router.push("/finalizar-compra").catch((err) => {});
      } else if (
        result.message == "Tienes productos que han superdado el stock."
      ) {
        this.status = "error";
        this.msg =
          "Tienes productos que han superdado el stock.  Producto: " +
          result.result.producto.nombre +
          " . Stock disponible: " +
          result.result.stock +
          " . Actualiza la cantidad y vuelve a intentarlo.";
        this.delStatus2();
      } else {
        localStorage.removeItem("kth");
        this.$router.push("/").catch((err) => {});
      }
      //
    },
    aplicarCupon: async function (cupon) {
      this.status = "";
      this.msg = "";
      let cliente = this.user.email;
      let result = await this.$store.dispatch("cart/addCupon", {
        cliente: cliente,
        cupon: cupon,
      });
      console.log(result);
      this.status = result.status;
      this.msg = result.message;
      this.delStatus();
    },
    goTienda() {
      this.$router.push("/tienda").catch((err) => {});
    },

    productDetail: function (url) {
     // name = name.replaceAll(" ", "-").replaceAll("/", "_");

      this.$router.push("/producto/" + url).catch((err) => {});
    },
    deleteItem: async function (index, codigo) {
      let result = await this.$store.dispatch("cart/deleteItem", {
        index: index,
        codigo: codigo,
      });
      this.status = result.status;
      this.msg = result.message;
      this.delStatus();
    },

    deleteCupon: async function (codigo) {
      let result = await this.$store.dispatch("cart/deleteCupon", {
        cupon: codigo,
      });
      this.status = result.status;
      this.msg = result.message;
      this.delStatus();
    },

    actualizar: async function (cart) {
      let result = await this.$store.dispatch("cart/updateCart", cart);
    
      this.status = result.status;
      this.msg = result.message;
      this.delStatus();
    },

    sum: async function (asd) {
      this.cart.detalle[asd].cantidad = this.cart.detalle[asd].cantidad + 1;
    },

    res: async function (asd) {
      if (this.cart.detalle[asd].cantidad >= 1) {
        return (this.cart.detalle[asd].cantidad =
          this.cart.detalle[asd].cantidad - 1);
      } else {
        return this.cart.detalle[asd].cantidad;
      }
    },
  },
};
</script>

<style scoped>
.div_BackTienda p a {
  color: white !important;
}

img.imglogoc {
  width: 1px;
  height: 1px;
}

.cupon-cont {
  width: 13vw;
  margin-right: auto;
  margin-left: 25vw;
}

.active-btn{
      background: var(--color-2);
    color: white;
    border-color: var(--color-2);
}

.cupon-cont p {
  text-align: center;
  font-family: "Rajdhani";
  color: #ff7a86;
  font-size: 1.2vw;
  margin: 0;
}

@media (max-width: 588px) {
  .cupon-cont p {
    font-size: 4.2vw;
  }
  .cupon-cont {
    width: fit-content;
    margin-right: auto;
    margin-left: 16vw;
    margin-top: 5vw;
  }
}
</style>


